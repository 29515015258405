import React, { useState } from 'react';
import RecipeForm from './RecipeForm';
import IngredientList from './IngredientList';
import { calculateIngredients, Ingredient, recipes } from './recipeUtils';

function App() {
  const [recipeName, setRecipeName] = useState('Basic Lathe');
  const [quantity, setQuantity] = useState(1);
  const [calculatedIngredients, setCalculatedIngredients] = useState<Ingredient[][]>([]);

  const handleCalculate = () => {
    const result = calculateIngredients(recipeName, quantity);
    setCalculatedIngredients(result);
  };

  return (
    <div className="flex justify-center w-full h-full bg-gray-50">
      <div className="p-4 space-y-4 w-full">
        <h1 className="text-2xl font-bold">Recipe Calculator</h1>

        <RecipeForm
          recipeName={recipeName}
          setRecipeName={setRecipeName}
          quantity={quantity}
          setQuantity={setQuantity}
          handleCalculate={handleCalculate}
          recipes={recipes}
        />

        <IngredientList calculatedIngredients={calculatedIngredients}/>
      </div>
    </div>

  );
}

export default App;
