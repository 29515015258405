export type Ingredient = {
  name: string;
  quantity: number;
};

export type Recipe = {
  ingredients: Ingredient[];
  method: string;
  makes: number;
};

export const recipes: Record<string, Recipe> = {
  'Electronic Circuit': {
    ingredients: [
      { name: 'Red Alloy Cable', quantity: 3 },
      { name: 'Vacuum Tube', quantity: 2 },
      { name: 'Resistor', quantity: 2 },
      { name: 'Circuit Board', quantity: 1 },
      { name: 'Steel Casing', quantity: 1 },
    ],
    method: 'Crafting',
    makes: 1,
  },
  'Vacuum Tube': {
    ingredients: [
      { name: 'Copper Wire', quantity: 3 },
      { name: 'Glass Tube', quantity: 1 },
      { name: 'Steel Rod', quantity: 2 },
      { name: 'Red Alloy Bolt', quantity: 1 },
      { name: 'Fine Copper Wire', quantity: 2 },
    ],
    method: 'Crafting',
    makes: 1,
  },
  'Fine Copper Wire': {
    ingredients: [
      { name: 'Copper Ingot', quantity: 1 },
    ],
    method: 'Wire-mill',
    makes: 8,
  },
  'Copper Foil': {
    ingredients: [
      { name: 'Copper Plate', quantity: 1 },
    ],
    method: 'Bending machine',
    makes: 4,
  },
  'Copper Plate': {
    ingredients: [
      { name: 'Copper Ingot', quantity: 1 },
    ],
    method: 'Bending machine',
    makes: 1,
  },
  'Copper Wire': {
    ingredients: [
      { name: 'Copper Ingot', quantity: 1 },
    ],
    method: 'Wire-mill',
    makes: 2,
  },
  'Red Alloy Cable': {
    ingredients: [
      { name: 'Red Alloy Wire', quantity: 1 },
      { name: 'Rubber Sheet', quantity: 1 },
    ],
    method: 'Crafting',
    makes: 1,
  },
  'Red Alloy Wire': {
    ingredients: [
      { name: 'Red Alloy Ingot', quantity: 1 },
    ],
    method: 'Wire-mill',
    makes: 2,
  },
  'Red Alloy Plate': {
    ingredients: [
      { name: 'Red Alloy Ingot', quantity: 1 },
    ],
    method: 'Bending machine',
    makes: 1,
  },
  'Copper Cable': {
    ingredients: [
      { name: 'Copper Wire', quantity: 1 },
      { name: 'Rubber Sheet', quantity: 1 },
    ],
    method: 'Crafting',
    makes: 1,
  },
  'Red Alloy Bolt': {
    ingredients: [
      { name: 'Red Alloy Rod', quantity: 1 },
    ],
    method: 'Crafting',
    makes: 2,
  },
  'Red Alloy Rod': {
    ingredients: [
      { name: 'Red Alloy Ingot', quantity: 1 },
    ],
    method: 'Lathe',
    makes: 1,
  },
  'Glass Tube': {
    ingredients: [
      { name: 'Glass Dust', quantity: 1 },
    ],
    method: 'Alloy Smelter',
    makes: 1,
  },
  'Glass Dust': {
    ingredients: [
      { name: 'Quartz Sand', quantity: 8 },
      { name: 'Flint Dust', quantity: 1 },
    ],
    method: 'Crafting',
    makes: 8,
  },
  'Quartz Sand': {
    ingredients: [
      { name: 'Sand', quantity: 1 },
    ],
    method: 'Macerator',
    makes: 1,
  },
  'Flint Dust': {
    ingredients: [
      { name: 'Flint', quantity: 2 },
    ],
    method: 'Macerator',
    makes: 4,
  },
  'Steel Rod': {
    ingredients: [
      { name: 'Steel Ingot', quantity: 1 },
    ],
    method: 'Lathe',
    makes: 1,
  },
  'Resistor': {
    ingredients: [
      { name: 'Sticky Resin', quantity: 2 },
      { name: 'Copper Wire', quantity: 2 },
      { name: 'Fine Copper Wire', quantity: 2 },
      { name: 'Coal Dust', quantity: 1 },
    ],
    method: 'Crafting',
    makes: 1,
  },
  'Steel Casing': {
    ingredients: [
      { name: 'Steel Ingot', quantity: 2 },
    ],
    method: 'Alloy Smelter',
    makes: 3,
  },
  'Circuit Board': {
    ingredients: [
      { name: 'Copper Wire', quantity: 8 },
      { name: 'Coated Circuit Board', quantity: 1 },
    ],
    method: 'Crafting',
    makes: 1,
  },
  'Coated Circuit Board': {
    ingredients: [
      { name: 'Wood Plank', quantity: 1 },
      { name: 'Sticky Resin', quantity: 2 },
    ],
    method: 'Crafting',
    makes: 1,
  },
  'Wood Plank': {
    ingredients: [
      { name: 'Wood Pulp', quantity: 8 },
    ],
    method: 'Compressor',
    makes: 1,
  },
  'Wood Pulp': {
    ingredients: [
      { name: 'Wood Log', quantity: 1 },
    ],
    method: 'Macerator',
    makes: 6,
  },
  'Rubber Sheet': {
    ingredients: [
      { name: 'Rubber Bar', quantity: 1 },
    ],
    method: 'Bending machine',
    makes: 1,
  },
  'Rubber Bar': {
    ingredients: [
      { name: 'Raw Rubber Dust', quantity: 3 },
      { name: 'Sulfur Dust', quantity: 1 },
    ],
    method: 'Alloy Smelter',
    makes: 1,
  },
  'Raw Rubber Dust': {
    ingredients: [
      { name: 'Sticky Resin', quantity: 1 },
    ],
    method: 'Extractor',
    makes: 3,
  },
  'Basic Bending Machine': {
    ingredients: [
      { name: 'LV Machine Hull', quantity: 1 },
      { name: 'Electric Piston (LV)', quantity: 2 },
      { name: 'Electric Motor (LV)', quantity: 2 },
      { name: 'Electronic Circuit', quantity: 2 },
      { name: 'Tin Cable', quantity: 1 },
    ],
    method: 'Crafting',
    makes: 1,
  },
  'Electric Motor (LV)': {
    ingredients: [
      { name: 'Copper Wire', quantity: 4 },
      { name: 'Iron Rod', quantity: 2 },
      { name: 'Tin Cable', quantity: 2 },
      { name: 'Magnetic Iron Rod', quantity: 1 },
    ],
    method: 'Crafting',
    makes: 1,
  },
  'Iron Rod': {
    ingredients: [
      { name: 'Iron Ingot', quantity: 1 },
    ],
    method: 'Lathe',
    makes: 1,
  },
  'Magnetic Iron Rod': {
    ingredients: [
      { name: 'Iron Ingot', quantity: 1 },
    ],
    method: 'Polarizer',
    makes: 1,
  },
  'Electric Piston (LV)': {
    ingredients: [
      { name: 'Steel Rod', quantity: 2 },
      { name: 'Steel Plate', quantity: 3 },
      { name: 'Tin Cable', quantity: 2 },
      { name: 'Electric Motor (LV)', quantity: 1 },
      { name: 'Small Steel Gear', quantity: 1 },
    ],
    method: 'Crafting',
    makes: 1,
  },
  'Tin Cable': {
    ingredients: [
      { name: 'Tin Wire', quantity: 1 },
      { name: 'Rubber Sheet', quantity: 1 },
    ],
    method: 'Crafting',
    makes: 1,
  },
  'Tin Wire': {
    ingredients: [
      { name: 'Tin Ingot', quantity: 1 },
    ],
    method: 'Wire-mill',
    makes: 2,
  },
  'Basic Lathe': {
    ingredients: [
      { name: 'Electronic Circuit', quantity: 2 },
      { name: 'LV Machine Hull', quantity: 1 },
      { name: 'Tin Cable', quantity: 3 },
      { name: 'Electric Motor (LV)', quantity: 1 },
      { name: 'Electric Piston (LV)', quantity: 1 },
    ],
    method: 'Crafting',
    makes: 1,
  },
  'LV Machine Hull': {
    ingredients: [
      { name: 'Tin Cable', quantity: 2 },
      { name: 'LV Machine Casing', quantity: 1 },
      { name: 'Wrought Iron Plate', quantity: 2 },
      { name: 'Steel Plate', quantity: 1 },
    ],
    method: 'Crafting',
    makes: 1,
  },
  'LV Machine Casing': {
    ingredients: [
      { name: 'Steel Plate', quantity: 8 },
    ],
    method: 'Crafting',
    makes: 1,
  },
  'Steel Plate': {
    ingredients: [
      { name: 'Steel Ingot', quantity: 1 },
    ],
    method: 'Bending machine',
    makes: 1,
  },
  'Iron Plate': {
    ingredients: [
      { name: 'Iron Ingot', quantity: 1 },
    ],
    method: 'Bending machine',
    makes: 1,
  },
  'Small Steel Gear': {
    ingredients: [
      { name: 'Steel Rod', quantity: 2 },
      { name: 'Steel Plate', quantity: 1 },
    ],
    method: 'Crafting',
    makes: 1,
  },
  'Basic Extruder': {
    ingredients: [
      { name: '4x Copper Wire', quantity: 4 },
      { name: 'LV Machine Hull', quantity: 1 },
      { name: 'Electric Piston (LV)', quantity: 1 },
      { name: 'Electronic Circuit', quantity: 2 },
      { name: 'Bronze Fluid Pipe', quantity: 1 },
    ],
    method: 'Crafting',
    makes: 1,
  },
  'Basic Semi-Fluid Generator': {
    ingredients: [
      { name: 'Tumbaga Gear', quantity: 2 },
      { name: 'LV Machine Hull', quantity: 1 },
      { name: 'Electronic Circuit', quantity: 1 },
      { name: 'Electric Motor (LV)', quantity: 2 },
      { name: 'Electric Piston (LV)', quantity: 2 },
      { name: 'Tin Cable', quantity: 1 },
    ],
    method: 'Crafting',
    makes: 1,
  },
  'Tumbaga Gear': {
    ingredients: [
      { name: 'Tumbaga Ingot', quantity: 4 },
    ],
    method: 'Extruder',
    makes: 1,
  },
  'Tumbaga Ingot': {
    ingredients: [
      { name: 'Tumbaga Dust', quantity: 1 },
    ],
    method: 'Smelt',
    makes: 1,
  },
  'Tumbaga Dust': {
    ingredients: [
      { name: 'Gold Dust', quantity: 1 },
      { name: 'Tumbaga Mix Dust', quantity: 3 },
    ],
    method: 'Crafting',
    makes: 10,
  },
  'Tumbaga Mix Dust': {
    ingredients: [
      { name: 'Gold Dust', quantity: 2 },
      { name: 'Copper Dust', quantity: 1 },
    ],
    method: 'Crafting',
    makes: 1,
  },
  'Copper Dust': {
    ingredients: [
      { name: 'Copper Ingot', quantity: 1 },
    ],
    method: 'Macerator',
    makes: 1,
  },
  'Gold Dust': {
    ingredients: [
      { name: 'Gold Ingot', quantity: 1 },
    ],
    method: 'Macerator',
    makes: 1,
  },
  '4x Copper Wire': {
    ingredients: [
      { name: 'Copper Ingot', quantity: 2 },
    ],
    method: 'Wire-mill',
    makes: 1,
  },
  'Bronze Fluid Pipe': {
    ingredients: [
      { name: 'Bronze Plate', quantity: 6 },
    ],
    method: 'Crafting',
    makes: 2,
  },
  'Bronze Plate': {
    ingredients: [
      { name: 'Bronze Ingot', quantity: 1 },
    ],
    method: 'Bending Machine',
    makes: 1,
  },
};

export function calculateIngredients(
  name: string,
  quantity: number,
): Ingredient[][] {
  const result: Record<string, number> = {};

  function helper(name: string, quantity: number) {
    result[name] = (result[name] || 0) + quantity;
    if (!recipes[name]) {
      return;
    }

    const recipe = recipes[name];
    const batches = Math.ceil(quantity / recipe.makes);

    for (const ingredient of recipe.ingredients) {
      helper(ingredient.name, ingredient.quantity * batches);
    }
  }

  helper(name, quantity);

  function recursiveDepth(name: string, depth = 0): number {
    const recipe = recipes[name];
    if (!recipe) return depth;

    return Math.max(...recipe.ingredients.map((i) => recursiveDepth(i.name, depth + 1)));
  }

  const depths: Record<string, Ingredient[]> = {};
  for (const key of Object.keys(result)) {
    const depth = recursiveDepth(key);

    if (!depths[depth]) depths[depth] = [];
    depths[depth].push({ name: key, quantity: result[key] });
  }

  return Object.keys(depths).map(k => depths[k]);
}
