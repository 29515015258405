import React, { useState } from 'react';
import { Ingredient, recipes } from './recipeUtils';
import { IconCheck, IconChevronDown, IconChevronUp } from '@tabler/icons-react';

interface IngredientListProps {
  calculatedIngredients: Ingredient[][];
}

function IngredientList({ calculatedIngredients }: IngredientListProps) {
  const [checked, setChecked] = useState<Record<string, boolean>>({});
  const [opened, setOpened] = useState('');

  function key(a: number, b: number): string {
    return `${a}-${b}`;
  }

  function toggleCheck(depth: number, ing: number) {
    const newChecked: Record<string, boolean> = { ...checked };

    newChecked[key(depth, ing)] = !newChecked[key(depth, ing)];

    setChecked(newChecked);
  }

  return (
    <div className="flex space-x-2 w-full">
      {calculatedIngredients.map((ingredients, depth) => (
        <div key={depth} className="mb-4 w-full space-y-2">
          <h2 className="text-xl font-semibold">Stage {depth + 1}</h2>

          <ul className="flex flex-col space-y-3">
            {ingredients.map((ingredient, index) => (
              <li
                key={index}
                className="bg-white shadow-md rounded-lg "
              >
                <div className="flex items-center justify-between space-x-2">
                  <div className="flex items-center space-x-1 pl-2 py-2">
                    {!!recipes[ingredient.name] && (
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          if (opened === key(depth, index)) {
                            setOpened('');
                          } else {
                            setOpened(key(depth, index));
                          }
                        }}
                      >
                        {opened === key(depth, index) ? (
                          <IconChevronUp/>
                        ) : (
                          <IconChevronDown/>
                        )}
                      </div>
                    )}

                    <span>{ingredient.quantity} x {ingredient.name}</span>
                  </div>

                  <div className="px-2">
                    <div
                      className={`rounded-full cursor-pointer min-w-6 min-h-6 w-6 h-6 flex items-center justify-center ${checked[key(depth, index)] ? 'bg-green-100' : 'bg-gray-100'}`}
                      onClick={() => toggleCheck(depth, index)}
                    >
                      <IconCheck size={18} className={checked[key(depth, index)] ? 'text-green-700' : 'text-gray-100'}/>
                    </div>
                  </div>
                </div>

                {opened === key(depth, index) && !!recipes[ingredient.name] && (
                  <div>
                    <hr/>

                    <div className="px-3 py-2">
                      <span className="font-semibold">
                        {recipes[ingredient.name].method}
                      </span>

                      <ul>
                        {recipes[ingredient.name].ingredients.map(ing => (
                          <li>
                            {ing.quantity * Math.ceil(ingredient.quantity / recipes[ingredient.name].makes)}
                            {' x '}
                            {ing.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default IngredientList;
