import React from 'react';
import { Recipe } from './recipeUtils';

interface RecipeFormProps {
  recipeName: string;
  setRecipeName: (name: string) => void;
  quantity: number;
  setQuantity: (quantity: number) => void;
  handleCalculate: () => void;
  recipes: Record<string, Recipe>;
}

function RecipeForm({
  recipeName,
  setRecipeName,
  quantity,
  setQuantity,
  handleCalculate,
  recipes,
}: RecipeFormProps) {
  return (
    <div className="space-y-4 p-6 bg-white shadow rounded-lg">
      <div>
        <label className="block mb-1">Recipe</label>
        <select
          value={recipeName}
          onChange={(e) => setRecipeName(e.target.value)}
          className="border p-2 w-full"
        >
          {Object.keys(recipes).sort().map((name) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block mb-1">Quantity</label>
        <input
          type="number"
          value={quantity}
          onChange={(e) => setQuantity(Number(e.target.value))}
          className="border p-2 w-full"
        />
      </div>

      <button
        onClick={handleCalculate}
        className="bg-black hover:bg-gray-900 px-4 py-2 rounded-full flex items-center justify-center"
      >
        <span className="text-gray-100 uppercase text-sm font-semibold">show ingredients</span>
      </button>
    </div>
  );
}

export default RecipeForm;
